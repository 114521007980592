import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import { isExpired } from '../libs/utils'

import Layout from '../components/layout'
import SEO from '../components/seo'
import PostCard from '../components/post-card'
import RainbowBanner from '../components/rainbow-banner'

// import "../utils/global.scss"
import '../utils/normalize.css'
import '../utils/css/screen.css'

const BlogIndex = ({ data }, location) => {
  const posts = data.allMarkdownRemark.edges
  let postCounter = 0

  return (
    <Layout>
      <SEO
        title="All Posts"
        keywords={[`market`, `rummage`, `sale`, `shopping`, `second hand`]}
      />
      {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title">
            {data.site.siteMetadata.description}
          </h2>
          <RainbowBanner />
        </header>
      )}
      <div className="post-feed">
        {posts
          .filter(({ node }) => {
            if (
              node.frontmatter &&
              node.frontmatter.date &&
              typeof node.frontmatter.expireAfterDays === 'number'
            ) {
              return !isExpired(
                node.frontmatter.date,
                node.frontmatter.expireAfterDays
              )
            }
            return true
          })
          .map(({ node }) => {
            postCounter++
            return (
              <PostCard
                key={node.fields.slug}
                count={postCounter}
                node={node}
                postClass={`post`}
              />
            )
          })}
      </div>
    </Layout>
  )
}

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        description
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            expireAfterDays
            title
            description
            price
            thumbnail {
              childImageSharp {
                fluid(maxWidth: 1360) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => (
  <StaticQuery
    query={indexQuery}
    render={data => (
      <BlogIndex location={props.location} props data={data} {...props} />
    )}
  />
)
