import React from 'react'
import Script from 'react-load-script'

import './rainbow-banner.css'

class RainbowBanner extends React.Component {
  constructor(props) {
    super(props)
    this.zdogPaintArea = React.createRef()
    this.zdogLibLoaded = this.zdogLibLoaded.bind(this)
    this.zdogPaint = this.zdogPaint.bind(this)
    this.state = {
      zdogLibLoaded: false,
    }
  }

  componentDidMount() {
    if (this.state.zdogLibLoaded === true) {
      this.zdogPaint()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.zdogLibLoaded === false &&
      this.state.zdogLibLoaded === true
    ) {
      this.zdogPaint()
    }
  }

  zdogLibLoaded() {
    this.setState({
      zdogLibLoaded: true,
    })
  }

  zdogPaint() {
    /* eslint-disable no-unused-vars */
    const Zdog = window.Zdog
    var red = '#f25774'
    var orange = '#ffb65b'
    var yellow = '#fdda74'
    var green = '#4cbe5d'
    var blue = '#4080ff'
    var purple = '#7b64c0'
    var brown = '#EB9678'
    var gray = '#E8E8E8'
    var raindowDiameter = 400
    var raindowStroke = 30
    var isSpinning = true
    var illo = new Zdog.Illustration({
      element: this.zdogPaintArea.current,
      dragRotate: true,
      resize: 'fullscreen',
      zoom: 1,
      onDragStart: function() {
        isSpinning = false
      },
      onDragEnd: function() {
        isSpinning = true
      },
      rotate: { x: -0.222, y: -202 },
    })

    var rectRed = new Zdog.Ellipse({
      addTo: illo,
      diameter: raindowDiameter,
      quarters: 2,
      stroke: raindowStroke,
      color: red,
      rotate: { x: Zdog.TAU / 2, z: Zdog.TAU / 4 },
      translate: { y: 100 },
    })

    var rectOrange = rectRed.copy({
      addTo: rectRed,
      diameter: raindowDiameter - 1 - raindowStroke * 2,
      rotate: null,
      color: orange,
      translate: null,
    })

    var rectYellow = rectOrange.copy({
      addTo: rectOrange,
      diameter: raindowDiameter - 1 - raindowStroke * 4,
      color: yellow,
    })

    var rectGreen = rectOrange.copy({
      addTo: rectYellow,
      diameter: raindowDiameter - 1 - raindowStroke * 6,
      color: green,
    })

    var rectBlue = rectOrange.copy({
      addTo: rectGreen,
      diameter: raindowDiameter - 1 - raindowStroke * 8,
      color: blue,
    })

    var rectPurple = rectOrange.copy({
      addTo: rectBlue,
      diameter: raindowDiameter - 1 - raindowStroke * 10,
      color: purple,
    })

    var cloud1 = new Zdog.Shape({
      addTo: illo,
      translate: { x: -160, y: -120, z: -150 },
      // no path set, default to single point
      stroke: 120,
      color: '#F5F5F3',
    })

    var cloudShape2 = cloud1.copy({
      addTo: cloud1,
      translate: { x: -30, y: 10, z: 10 },
      stroke: 96,
      color: '#FAFAF8',
    })

    var cloudShape3 = cloud1.copy({
      addTo: cloud1,
      translate: { x: 50, y: 10 },
      stroke: 70,
      color: '#FAFAF8',
    })

    var cloudShape4 = cloud1.copy({
      addTo: cloud1,
      translate: { x: 0, z: 20 },
      stroke: 96,
      color: '#F5F5F3',
    })

    var cloud2 = cloud1.copyGraph({
      translate: { x: 140, y: -60, z: 150 },
    })

    function animate() {
      illo.rotate.y -= isSpinning ? 0.02 : 0
      illo.updateRenderGraph()
      requestAnimationFrame(animate)
    }
    animate()
    /* eslint-enable no-unused-vars */
  }

  render() {
    return (
      <>
        <Script
          url="https://unpkg.com/zdog@1/dist/zdog.dist.min.js"
          onLoad={this.zdogLibLoaded}
        />
        <svg className="rainbow-banner" ref={this.zdogPaintArea}></svg>
        <small className="rainbow-banner-credit">
          Credit:&nbsp;
          <a
            href="https://codepen.io/Phong6698/pen/OYGVRE"
            target="_blank"
            rel="noopener noreferrer"
          >
            Chiramet Phong Penglerd
          </a>
        </small>
      </>
    )
  }
}

export default RainbowBanner
