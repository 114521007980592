const { DateTime, Duration } = require(`luxon`)

/**
 * Check if an expiration date had reached.
 *
 * @param {string} startDateStr The start date.
 * @param {number} expireAfterDays How many days are there before expire.
 * @param {string} optionalFormat Optional format to instruct date string parsing.
 * @return {boolean} Whether expiration date had reached or not.
 */
exports.isExpired = (
  startDateStr,
  expireAfterDays,
  optionalFormat = 'LLLL dd, yyyy'
) => {
  const startDate = DateTime.fromFormat(startDateStr, optionalFormat)
  if (startDate.isValid && typeof expireAfterDays === 'number') {
    const expireDate = startDate.plus(
      Duration.fromObject({ days: expireAfterDays })
    )
    const endOfToday = DateTime.fromObject({ hour: 23, minute: 59, second: 59 })
    return expireDate < endOfToday
  }
  return false
}
