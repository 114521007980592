import React from 'react'
import { Link } from 'gatsby'

class PostCardTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.postCardRef = React.createRef()
    this.postCardContentRef = React.createRef()
  }

  componentDidMount() {}

  render() {
    const props = this.props
    return (
      <article
        className={`post-card ${props.count % 3 === 0 && `post-card-large`} ${
          props.postClass
        } ${props.node.frontmatter.thumbnail ? `with-image` : `no-image`}`}
        ref={this.postCardRef}
      >
        <Link className={`post-card-link`} to={props.node.fields.slug}>
          <div
            // to={props.node.fields.slug}
            className={'post-card-wrapper'}
            style={
              props.node.frontmatter.thumbnail && {
                backgroundImage: `url(${props.node.frontmatter.thumbnail.childImageSharp.fluid.src})`,
              }
            }
          >
            <div className="post-card-content" ref={this.postCardContentRef}>
              <h2 className="post-card-title">
                <span>
                  {props.node.frontmatter.title || props.node.fields.slug}
                </span>
              </h2>
              {props.node.frontmatter.price ? (
                <h2 className="post-card-price">
                  {props.node.frontmatter.price}
                </h2>
              ) : null}
              {props.node.frontmatter.date ? (
                <h6 className="post-card-date">
                  Posted on {props.node.frontmatter.date}
                </h6>
              ) : null}
            </div>
          </div>
        </Link>
        <h2 className="post-card-price-overlay">
          {props.node.frontmatter.price}
        </h2>
      </article>
    )
  }
}

export default PostCardTemplate
